import React, { useEffect } from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
`

const RsvpPage = () => {
  useEffect(() => {
    document
      .getElementById("google-form")
      .addEventListener("load", () => window.scrollTo(0, 0))
  }, [])

  return (
    <Layout>
      <SEO title="RSVP | Sara & Tim" />
      <Container>
        <iframe
          id="google-form"
          src="https://docs.google.com/forms/d/e/1FAIpQLScig8JUOylUxPYvHVXuSYbrnkDlP5eq9MYv617AYoJV-wSo4Q/viewform?embedded=true"
          width="100%"
          height="2878"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </Container>
    </Layout>
  )
}

export default RsvpPage
